---
title: 8 months without posts
date: 2020-07-09T20:10:00
description: You shouldn't be looking at this. This is a post from the future
published: false
lang: en
keywords: not yet published, not yet written, 404, not found
slug: future/hiatus
---

You've accidentally stumbled upon a hidden post.

## The future

This post belongs to the future.

<iframe title="time loop" src="https://giphy.com/embed/3ohhwiSbK4IdpTIB0Y" width="480" height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/time-endless-history-3ohhwiSbK4IdpTIB0Y">via GIPHY</a></p>


The author is trying to bring it to our space-time through mental power and the most advanced technology.

## Help us

The author need all your help and support to gather energy for this task!

Send him your energy via emails, twitter or GitHub!
