---
title: Hello 👋 World 🌎
date: 2019-03-01T22:12:03
description: The first post on this blog! About my intent and this blog's purpose
published: true
lang: en
tags: first, starting
keywords: hello world, first post, starting new blog
slug: hello-world
---

## Hi! 👋

This is my first post on my new blog! How exciting!

I'm sure I'll write a lot more interesting things in the future.

## Purpose 🤔

### Open Source

My intent for this blog is for it to be fully **Open Source**.

You can check all the commits [on the Github Repository](https://github.com/doppelganger9/blog).

### Tech stack

At first, I built it with [GatsbyJS](https://www.gatsbyjs.org/) and ⚛️**React**.

#### and migrations

Since then, it has been migrated to [Sapper](https://sapper.svelte.dev), powered by [Svelte](https://svelte.dev).

## Building

I will post here at first to explain & illustrate technicall stuff while building this blog, instead of using vague example use-cases.

I like being pragmatic & practical and I want to show this!

Also, I want to show you the meandering in finding a working thing, take a ride with me instead of just showing you the perfected result.

## Frequency

I intended to post at least once in a while. Oh, wow, now _that_ is ambition! 🤪

## Contact

At first I wanted to add the possibility to comment on posts, but if you really want to contribute or interact with me or this blog, choose whatever suits you:

- my DMs are open on twitter:

<a href="https://twitter.com/messages/compose?recipient_id=48112723&ref_src=twsrc%5Etfw" class="twitter-dm-button" data-size="large" data-screen-name="doppelganger9" data-show-count="false">Message @doppelganger9</a>

- mail me at [david@lacourt.dev](mailto://david@lacourt.dev)
- on [this blog's repository on GitHub](https://github.com/doppelganger9/blog), open an Issue or comment a Pull-Request...
