---
title: Using ObservableHQ to experiment with literate programming.
date: 2019-03-17T14:50:03
published: true
description: A post about how I used ObservableHQ a few months ago to create a notebook about using JavaScript Generators to create a time-traveling visualization of an algorithm.
tags: ObservableHQ, notebook, javascript, generators
category: Dev
lang: en
keywords: observablehq, notebook, javascript, fun fun function, mpj, generators, es6, ecmascript, javascript
slug: 2019/03/17
---

<script>
  import AddTwitterWidgetScript from '$lib/components/AddTwitterWidgetScript.svelte';
</script>

<AddTwitterWidgetScript />

Unearthing some old tweet to share with you today:

https://twitter.com/doppelganger9/status/1052854967512510464

## Observable HQ

> a magic notebook for visualization.

[Check the introduction](https://observablehq.com/@observablehq/five-minute-introduction) for yourself: it is something that you need to manipulate to better understand (a.k.a. *Kinesthetic learning*).

You may have noticed from [previous](/2019/03/14) [posts](/2019/03/16) that I'm really fond of the Fun Fun Function YouTube channel.

Inspired by [MPJ](https://twitter.com/mpjme), I tried to use the [ObservableHQ website](https://observablehq.com/) to do some kind of literate programming or expressive playground: combining code with text in a readable form.

https://youtu.be/MmRmxzrr4lk

Here is the result of this experiment from *October, 18th of 2018*.

## Using JavaScript Generators to create a time-traveling visualization of an algorithm

Link to this [Observable notebook](https://observablehq.com/@doppelganger9/algorithm-debugging-using-a-time-travelling-javascript-ge).

*I will try to embed it directly here, but for now, you'll have to visit the link above.*

## Conclusion

*Wow, that was a quick post. Unless you read the Notebook linked, that is.*

As always, I learned a ton on the way and ended up with nice executable documentation for a concept helping me visualize how algorithm work.

I hope you too learned a few things.

> Thanks for reading this blog, If you have any questions, please use the Github Repository's Issues to start a conversation, or use Twitter: my DMs are open.

👋
