---
title: Migrating to Lumen starter
date: 2019-03-18T09:00:00
description: You shouldn't be looking at this. This is a post from a parallel universe
published: false
lang: en
keywords: not yet published, not yet written, 404, not found, lumen starter
slug: alternate-reality/migrating-to-lumen-starter
---

You've accidentally stumbled upon a hidden post.

## An Alternate Reality

This post belongs to another dimension.

<div style="width:100%;height:0;padding-bottom:56%;position:relative;"><iframe title="another dimension" src="https://giphy.com/embed/igGApTvcxnJkgzOIVb" width="100%" height="100%" style="position:absolute" frameBorder="0" class="giphy-embed" allowFullScreen></iframe></div><p><a href="https://giphy.com/gifs/season-1-episode-6-s1-igGApTvcxnJkgzOIVb">via GIPHY</a></p>


The author is trying to bring it to our space-time through mental power and the most advanced technology.

## Help us

The author need all your help and support to gather energy for this task!

Send him your energy via emails, twitter or GitHub!
